body {
    background-color: #f8f9fa!important;
    box-sizing: border-box;
}

.custom-date {
    .react-datepicker-wrapper {
        width: 100%;
    }
}

input[type="text"]:disabled {
    color: #000;
    background: transparent;
}
